import React from "react";
import { SEO, PageLayout, Background, Content } from "@bluefin/components";
import { Header, Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class PressPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background columns={1} stackable={true} className={"press-hero"} />
          }
          subfooter={false}
          className={"press-page"}
        >
          <Content className={"press-content"}>
            <Content.Markup isMarkdown={false} textAlign={"center"} width={16}>
              <Header as={"h1"}>Press</Header>
            </Content.Markup>
          </Content>
          <Grid
            stackable={true}
            className={""}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={14}>
              <iframe
                src={"https://www.youtube.com/embed/roIi2nzrcxE"}
                frameborder={"0"}
                width={"820px"}
                height={"480px"}
                title={"YouTube video player"}
                allowfullscreen={true}
                allow={
                  "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                }
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={""}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={14}>
              <iframe
                src={
                  "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1484418040%3Fsecret_token%3Ds-ZZY65rNhROO&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                }
                frameborder={"0"}
                width={"85%"}
                height={"300"}
                scrolling={"no"}
                allow={"autoplay"}
              />
              <div className={"soundcloud-container"}>
                <a
                  target={"_blank"}
                  title={"Support Team"}
                  href={"https://soundcloud.com/support-team-168001126"}
                >
                  Support Team
                </a>
                <a
                  target={"_blank"}
                  title={"VIEWPOINT+THE VINTAGE W LISA JONES"}
                  href={
                    "https://soundcloud.com/support-team-168001126/viewpointthe-vintage-w-lisa-jones/s-ZZY65rNhROO"
                  }
                >
                  VIEWPOINT+THE VINTAGE W LISA JONES
                </a>
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Press" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
